'use client';

import { Offer, OfferV6 } from '@/model/offer';
import Spacer from '../ui/Spacer';
import Button from '../ui/Button';
import OfferPayout from '../OfferPayout';
import { forwardRef } from 'react';
import {
  convertUnixTimeStamp,
  formatSmartNumber,
  getOfferType,
  secondsToReadableTime
} from '@/utils';
import ArrowDown from '/public/arrow-down.svg';
import TimeIcon from '/public/icons/time.svg';
import UpIcon from '/public/icons/upvote_filled.svg';
import Link from 'next/link';
import LazyImage from '../LazyImage';

/**
 * A React component for offer Item
 *
 * @component
 * @param {Object} props - The component props.
 * @param {OfferV6} props.offer - The offer.
 * @param {Boolean} props.isLarge - The size of offer item.
 * @param {String} props.containerClass - The class of offer container.
 * @param {Number} props.index - The index of offer in the list.
 * @returns {JSX.Element} A React JSX element representing the component.
 * @example
 */

const RegularOfferCardV2 = forwardRef(
  (
    {
      offer,
      containerClass,
      openOfferDetails,
      handleCardClick,
      isCtaLoading,
      tagMap
    },
    ref
  ) => {
    const ttfp = offer.metrics?.ttfp_ms / 1000;
    const tags = offer.tags?.filter(d => d !== tagMap?.NEW.id);
    const isNew = !!offer.tags?.find(d => d === tagMap?.NEW.id);
    const tutorialUrl = !!offer.tutorialUrl;

    const getFormattedNumber = num => {
      return formatSmartNumber(num, {
        allowDecimalPoint: false,
        minNumLength: 3,
        roundUpToLargeUnit: true
      }).toString();
    };

    return (
      <Link
        href={`/offer/${offer.offerId}`}
        ref={ref}
        onClick={handleCardClick}
        className={`flex flex-col rounded-2xl overflow-hidden border border-[#E7E7E7] landscape:w-[350px] relative offer-card-gradient p-2`}
      >
        <div className="grid grid-cols-[90px_1fr] gap-2">
        <LazyImage
            src={offer?.iconImage}
            alt={offer?.title}
            className="w-[90px] aspect-square rounded-2xl border-[#C0C0C0] border"
          />
          <div className="flex flex-col">
            <p className="text-xs text-black/60">
              {getOfferType(offer.offerType)}
            </p>
            <h1 className="font-bold text-black line-clamp-1">
              {offer?.title}
            </h1>

            <div className="flex items-center justify-start gap-2">
              {ttfp ? (
                <div className="flex items-center gap-1 p-1 mt-1 font-medium border rounded-md text-black/60 border-black/60 w-fit mr-1">
                  <TimeIcon className="text-xs " />
                  <p className="text-xs">{secondsToReadableTime(ttfp)}</p>
                </div>
              ) : null}
              {offer.metrics.upvotes > 0 && (
                <div className="flex items-center gap-1">
                  <UpIcon className={'text-black mb-1'} />
                  <p className="mt-1 text-sm text-black">
                    {getFormattedNumber(offer.metrics.upvotes)}
                  </p>
                </div>
              )}
            </div>
            {tags?.length ? (
              <div className="flex flex-wrap items-center gap-1 mt-1 line-clamp-1">
                {tags.map((d, i) => (
                  <div className="flex items-center" key={i}>
                    {i !== 0 && (
                      <span className="w-1 h-1 mr-1 rounded-full bg-black/60" />
                    )}
                    <p className="text-xs text-black/60">{tagMap[d].display}</p>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex items-center gap-1 mt-2">
          {/* <Button
            color="secondary"
            variant="contained"
            className="w-full px-2 font-bold m-w-fit"
            // onClick={openOfferDetails}
          >
            View Details{' '}
            <ArrowDown className={`text-lg ${'text-primary -rotate-90'}`} />
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            shine
            className="w-full px-5 font-bold"
            isLoading={isCtaLoading}
          >
            <OfferPayout size="medium" amount={offer?.payout?.amount} />
          </Button>
        </div>
        {tutorialUrl ? null : isNew ? ( // </div> //   <p className="text-xs font-medium text-black">Task Video</p> // <div className="absolute right-0 top-0 flex items-center justify-center bg-gradient-to-r from-[#FFAB35] to-[#F5B75F] h-fit w-fit px-2 py-1 rounded-tr-lg rounded-bl-lg">
          <div className="absolute right-0 top-0 flex items-center justify-center bg-gradient-to-r from-[#FFAB35] to-[#F5B75F] h-fit w-fit px-2 py-1 rounded-tr-lg rounded-bl-lg">
            <p className="text-xs font-medium text-black">New</p>
          </div>
        ) : null}
      </Link>
    );
  }
);

RegularOfferCardV2.displayName = 'RegularOfferCardV2';

export default RegularOfferCardV2;
